import { Grid } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTinyUrlDomain } from '@japieglobal/shared/src/api/utils';
import { LoadingWrapper } from '@japieglobal/shared/src/components';
import { formatDateAsDateHourAndMinute } from '@japieglobal/shared/src/utils';
import { useQueryString, useSnackbarErrorHandler } from '@japieglobal/shared/src/hooks';
import { AdminMainWrapper } from '../admin-main-wrapper';
import { AdminQueryStateKey } from '../hooks/admin-query-state';
import { DownloadIcon } from '../components/icons';
import { exportActivities, getActivities, UserRoles } from '@japieglobal/shared/src/api/services';
import { UserContext } from '@japieglobal/shared/src/user-context';

const StyledDataGrid = styled(DataGrid)({
  'backgroundColor': 'white',
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: 'white',
  },
});

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiDataGrid-colCellTitle': {
      fontSize: '12px',
      color: 'rgba(0,0,0,.54)',
    },
    '& .MuiDataGrid-cell': {
      fontSize: '13px',
      color: 'rgba(0,0,0,.87)',
    },
  },
}));

export const AdminMainActivityDetailList = () => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { snackbarErrorHandler } = useSnackbarErrorHandler();
  const styles = useStyles();
  const [activities, setActivities] = useState<any[]>([]);
  const [activityType] = useQueryString<string>(AdminQueryStateKey.ACTIVITY_TYPE, '');
  const [dateFrom] = useQueryString<string>(AdminQueryStateKey.DATE_FROM, '', false);
  const [dateTo] = useQueryString<string>(AdminQueryStateKey.DATE_TO, '', false);
  const [userId] = useQueryString<string | undefined>(AdminQueryStateKey.USER_ID, undefined);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [total, setTotal] = useState(0);
  const { t } = useTranslation();

  const formatBackendToFrontendUrl = (value: string) => {
    return value.replace('http://api.', 'https://').replace('/api/', '/').replace('/window?', '/cars?');
  };

  const addDealer = (value: string, dealer: string) => {
    if (!value) return;
    const url = new URL(value);
    url.searchParams.set('switch_to_dealer', dealer);
    return url.toString();
  };

  const columns: GridColDef[] = useMemo(() => {
    const baseColumns = [
      {
        field: 'activity',
        headerName: 'Type',
        width: 200,
        flex: 1,
        sortable: false,
        renderCell: (params) => <div>{t(params.row.activity).toUpperCase()}</div>,
      },
      {
        field: 'details',
        headerName: 'Details',
        width: 400,
        cellClassName: () => 'additional-data',
        sortable: false,
        renderCell: (params) => {
          return (
            <div style={{ lineHeight: '15px' }}>
              {['TINY_URL', 'EXTERNAL_TINY_URL'].includes(params.row.activity) ? (
                <>
                  <a href={`${getTinyUrlDomain()}/${params.row.additional_data?.url}`} target="_blank">
                    {params.row.additional_data?.url}
                  </a>
                </>
              ) : (
                Object.entries(params.row.additional_data || {}).map(([key, value]) => {
                  if (key === 'url') {
                    if (['WINDOW', 'THUMBS', 'SEARCH_CARS'].includes(params.row.activity)) {
                      value = formatBackendToFrontendUrl(value as string);
                    }
                    return (
                      <a href={addDealer(value as string, params.row.dealer)} target="_blank">
                        {value as string}
                      </a>
                    );
                  }
                  if (key === 'urls') {
                    return (value as string[]).map((v) => (
                      <div>
                        <a href={v as string} target="_blank">
                          {v as string}
                        </a>
                      </div>
                    ));
                  }
                  if (typeof value === 'object') {
                    return <div>{`${key} = ${JSON.stringify(value, null, 2)}`}</div>;
                  }
                  return <div>{`${key} = ${value}`}</div>;
                })
              )}
            </div>
          );
        },
      },
      {
        field: 'full_name',
        headerName: t('FIRST_NAME'),
        flex: 1,
        sortable: false,
        renderCell: (params) => <div>{params.row.user.full_name}</div>,
      },
      {
        field: 'email',
        headerName: 'E-Mail',
        flex: 1,
        sortable: false,
        renderCell: (params) => <div>{params.row.user.email}</div>,
      },
      {
        field: 'created_at',
        headerName: t('CREATED_ON'),
        flex: 1,
        sortable: false,
        renderCell: (params) => <div>{formatDateAsDateHourAndMinute(new Date(params.row.created_at))}</div>,
      },
    ];
    if (user.role === UserRoles.SUPER_ADMIN) {
      const tokenColumn = {
        field: 'token',
        headerName: t('TOKEN'),
        flex: 1,
        sortable: false,
        renderCell: (params) => <div>{params.row.token}</div>,
      };
      return baseColumns.concat([tokenColumn]);
    }
    return baseColumns;
  }, [t, user.role]);

  useEffect(() => {
    setIsLoading(true);
    getActivities({
      limit,
      offset,
      date_from: dateFrom || undefined,
      date_to: dateTo || undefined,
      is_service_account: !!userId,
      show_super_admins: false,
      activity: activityType,
      user_id: userId || undefined,
    })
      .then((res) => {
        setActivities(res.data as any);
        setTotal(res.total as any);
      })
      .catch(snackbarErrorHandler)
      .finally(() => setIsLoading(false));
  }, [snackbarErrorHandler, dateFrom, dateTo, limit, offset, activityType, userId]);

  const onClickDownload = useCallback(() => {
    exportActivities({
      date_from: dateFrom || undefined,
      date_to: dateTo || undefined,
      is_service_account: false,
      show_super_admins: false,
      activity: activityType,
      user_id: userId || undefined,
    }).catch(snackbarErrorHandler);
  }, [snackbarErrorHandler, dateFrom, dateTo, activityType, userId]);

  return (
    <AdminMainWrapper title={'Activities Details'}>
      <LoadingWrapper isLoading={isLoading}>
        <Grid container justifyContent="flex-end">
          <DownloadIcon onClick={onClickDownload} />
        </Grid>
        <StyledDataGrid
          rowHeight={80}
          loading={isLoading}
          className={styles.root}
          rows={activities}
          disableRowSelectionOnClick
          columns={columns}
          onPaginationModelChange={(param) => {
            setLimit(param.pageSize);
            setPage(param.page);
            setOffset(limit * param.page);
          }}
          paginationModel={{ pageSize: limit, page: page }}
          rowCount={total}
          paginationMode="server"
          sx={() => ({
            '.additional-data': {
              overflow: 'auto',
            },
          })}
        />
      </LoadingWrapper>
    </AdminMainWrapper>
  );
};
