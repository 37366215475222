import React, { useContext, useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { Circle, MapContainer, Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import L, { type LatLngExpression } from 'leaflet';
import { CarResult, MapDataResult } from '@japieglobal/shared/src/types';
import { getMapDataByIds } from '@japieglobal/shared/src/api/services';
import { Dialog, DialogContent, Grid, IconButton } from '@mui/material';
import SatelliteIcon from '@mui/icons-material/Satellite';
import CloseIcon from '@mui/icons-material/Close';
import { settings } from '@japieglobal/shared/src/settings';
import { getMoney } from '@japieglobal/shared/src/utils';
import { UserContext } from '@japieglobal/shared/src/user-context';
import MarkerClusterGroup from 'react-leaflet-cluster';

const mapStyle = { height: '100vh', width: '100vw' };

export const CarWindowMap = ({ ownCar, windowCarIds }: { ownCar: CarResult; windowCarIds: number[] | undefined }) => {
  const [cars, setCars] = useState<MapDataResult[]>([]);
  const [showMap, setShowMap] = useState(false);
  const { user } = useContext(UserContext);
  useEffect(() => {
    if (windowCarIds && showMap) getMapDataByIds(windowCarIds).then(setCars);
  }, [showMap, windowCarIds]);
  if (!windowCarIds) return null;


  const getMarker = new L.Icon({
    iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
    iconSize: [25, 41],
  });

  const getIconOwnCar = (car: CarResult) => {
    let imageUrl: string;
    const imageScalar = 2;
    let iconSize: [number, number];
    if (car.id === 100000000) {
      imageUrl = car.dealer!.logo!;
      iconSize = [43 * imageScalar, 10.6 * imageScalar];
    } else {
      imageUrl = car.image!;
      iconSize = [43 * imageScalar, 32.2 * imageScalar];
    }
    return new L.Icon({
      iconUrl: imageUrl,
      iconSize: iconSize,
    });
  };

  const polygon: LatLngExpression[] = ownCar.trace?.conditions?.find((r) => r['operator'] == 'operator_polygon')?.value;
  const radius = ownCar.trace?.conditions?.find((r) => r['operator'] == 'operator_lat_long')?.value[0];
  return (
    <>
      <SatelliteIcon onClick={() => setShowMap(!showMap)} />
      <Dialog fullScreen open={showMap} onClose={() => setShowMap(false)}>
        <DialogContent>
          <Grid container flexDirection={'row'}>
            <Grid container item justifyContent={'flex-end'} position={'absolute'} zIndex={1000}>
              <IconButton size="large" onClick={() => setShowMap(false)}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item>
              <MapContainer
                i18nIsDynamicList
                center={[ownCar.latitude!, ownCar.longitude!]}
                zoom={10}
                style={mapStyle}
                maxZoom={20}
              >
                <TileLayer
                  url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <MarkerClusterGroup>
                  {cars
                    .filter((car) => car.longitude && car.longitude)
                    .map((car) => (
                      <Marker icon={getMarker} position={[car.latitude!, car.longitude!]}>
                        <Popup>
                          <img width={'200px'} src={`https://image.${settings().country}.jp.cars/${car.image_url}`} />
                          <div>{getMoney(user, car.price_local, 8)}</div>
                          <div>{`${car.location_name}`}</div>
                          <div>{`${car.dealer_name}`}</div>
                        </Popup>
                      </Marker>
                    ))}
                </MarkerClusterGroup>
                {polygon && <Polyline color="black" positions={polygon} />}
                {radius && (
                  <Circle center={[ownCar.latitude!, ownCar.longitude!]} color="black" radius={radius * 1000} />
                )}
                <Marker icon={getIconOwnCar(ownCar)} position={[ownCar.latitude!, ownCar.longitude!]}>
                  <Popup>
                    <img width={'200px'} src={ownCar.image} />
                    <div>{getMoney(user, ownCar.rank?.target_value, 8)}</div>
                    <div>{`${ownCar.location_name}`}</div>
                    <div>{`${ownCar.dealer?.name}`}</div>
                  </Popup>
                </Marker>
              </MapContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
