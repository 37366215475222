import { CarMessage } from '../../car-message/car-message-types';
import { AverageTrendResult, CarResult, CarTurnover, MapDataResult } from '../../types';
import { responseFileDownload } from '../utils/downloadFile';
import { instance } from './instance';
import { sendMessage } from './common';
import { Pageable } from '../../types/pagination';
import { CarQuery } from './taxation';

export interface CarResultsNew {
  results: CarResult[];
  pagable: Pageable;
}

export interface DealerSearchResponse {
  results: DealerSearchEntry[];
  pageable: Pageable;
}

export interface DealerSearchEntry {
  name: string;
  ftp_key: string;
  notification_days: number;
}

interface ExportCarsParams extends CarQuery {
  dump_hidden: boolean;
  dump_nonvaluated: boolean;
  dump_price: boolean;
  format: string;
  lang: string;
}

export const exportCars = async (props: ExportCarsParams): Promise<void> =>
  instance
    .get(`/api/cars/export`, { params: props, responseType: 'blob' })
    .then(responseFileDownload(`jpcars-${new Date().valueOf()}`));

export const exportCarsHistory = async (date: string): Promise<void> =>
  instance
    .get('/api/cars/export/history', { params: { date }, responseType: 'blob' })
    .then(responseFileDownload(`jpcars-${date}`));

export const carMessages = async (carIds: number[]): Promise<Record<string, CarMessage[]>> =>
  instance
    .get(`/api/messages`, { params: { car_ids: carIds } })
    .then((res) => res.data)
    .catch((err) => console.error(err));

export const patchVisibilityCar = async (props: { hidden: boolean; id: string; messageContent: string, hidden_days?: number }) =>
  instance.patch(`/api/cars/${encodeURIComponent(props.id)}`, { hidden: props.hidden, hidden_days: props.hidden_days }).then(() =>
    sendMessage({
      car_id: props.id,
      content: props.messageContent,
    }),
  );

export const getCarsList = async (props: CarQuery): Promise<CarResultsNew> =>
  instance.get(`/api/cars`, { params: props }).then((res) => res.data);

export const carMessageMarkAsRead = async (body: { car_id?: string }) => instance.post(`/api/messages/read`, body);

export const dealerSearch = async (props: { name: string }): Promise<DealerSearchResponse> =>
  instance.get(`/api/dealers`, { params: props }).then((res) => res.data);

export const getCarTurnover = async (props: { carId: string }): Promise<CarTurnover> =>
  instance.get(`/api/car/${props.carId}/turnover`).then((res) => res.data);

export const getCarTurnoverByIds = async (carIds: number[]): Promise<AverageTrendResult> =>
  instance.post(`/api/average_car_turnover`, { window_car_ids: carIds }).then((res) => res.data);

export const getMapDataByIds = async (carIds: number[]): Promise<MapDataResult[]> =>
  instance.post(`/api/map-data`, { window_car_ids: carIds }).then((res) => res.data);

export const getCarClicks = async (props: { carId: string }): Promise<number[]> =>
  instance.get(`/api/car/${props.carId}/click_history`).then((res) => res.data);

export const applyCustomDataCars = async (dealer: string) =>
  instance.post(`/api/dealers/${dealer}/apply_custom_data`).then((res) => res.data);

export const getDealer = async (dealer: string) =>
  instance.get(`/api/dealers/${dealer}`).then((res) => res.data);
